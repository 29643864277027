import { inject, Pipe, PipeTransform } from '@angular/core';

import { NotificationType } from '../../../../shared/enums/notifications/notification-type.enum';
import { UserConfigsStoreFacade } from '../../../../store/user-configs/user-configs-store.facade';

@Pipe({
  name: 'notificationInfoText',
  standalone: true
})
export class NotificationInfoTextKeyPipe implements PipeTransform {
  private userConfigsFacade = inject(UserConfigsStoreFacade);

  public transform(notificationType: NotificationType): string {
    switch (notificationType) {
    case NotificationType.ITEM_CHANGED:
      return this.userConfigsFacade.isExternalAsSignal()
        ? 'The item of { rpId } has been changed by your Service Provider. Please check the process details or the activity log.'
        : 'The item of { rpId } has been changed. Please check the process details or the activity log.';
    case NotificationType.NEWLY_CREATED_PURCHASE_EXISTS:
      return '{ customer } has purchases with status newly created';
    case NotificationType.APPROVED_PROCESS_EXISTS:
      return '{ customer } has approved processes';
    case NotificationType.REJECTED_PROCESS_EXISTS:
      return '{ customer } has rejected processes';
    case NotificationType.APPROVAL_REQUIRED_PROCESS_EXISTS:
      return '{ customer } Your Service provider asks for your approval';
    case NotificationType.DELAYED_PROCESS:
      // eslint-disable-next-line @stylistic/max-len
      return 'Status for { rpId } has not been changed for more than 14 days, please check the replenishment process and change the status accordingly to the physical workflow of the item';
    default:
      return '';
    }
  }
}
